<template>
  <div>
    <custom-toolbar
      :total="$store.getters['proxiMargin/total']"
      :title="$t('Proximity')"
      :add="'Nouvelle marge'"
      @refresh="getItems"
      @add="openForm"
    />
    <v-card class="mb-6 mt-6">
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.proxiMargin.field.margin"
              label="Rechercher par la marge"
              outlined
              dense
              placeholder="Rechercher par la marge"
              hide-details
              clearable
              @input="getItemsByFields"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.proxiMargin.field.min_distance"
              label="Rechercher par la distance minimale"
              outlined
              dense
              placeholder="Rechercher par la distance minimale"
              hide-details
              clearable
              @input="getItemsByFields"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="$store.state.proxiMargin.field.max_distance"
              label="Rechercher par la distance maximale"
              outlined
              dense
              placeholder="Rechercher par la distance maximale"
              hide-details
              clearable
              @input="getItemsByFields"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="isDialogVisible"
        :headers="headers"
        :items="$store.getters['proxiMargin/proxiMargins']"
        :items-per-page="itemsPerPage"
        :page.sync="currentPage"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            @activate="deleteItem(item)"
            :activate="true"
            @edit="openForm(item)"
            :status="false"
            :view="false"
            :lock="false"
            :remove="false"
          />
        </template>
        <template #[`item.is_active`]="{item}">
          <v-chip
            small
            :color="item.is_active ? 'primary' : 'error'"
            :class="`${item.is_active ? 'primary' : 'error'}--text`"
            class="v-chip-light-bg font-weight-bold"
          >
            {{ item.is_active === true ? 'OUI' : 'NON' }}
          </v-chip>
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.proxiMargin.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getItemsPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getItems"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <proxi-margin-form-dialog
      :dialog="dialog"
      :proxi-margin="proxiMargin"
      @clickout="closeDialog"
    />
    <confirm-dialog
      :message="confirmMessage"
      :dialog-delete="dialogDelete"
      @clickout="closeDelete"
      @submitted="activateProxiMargin"
    />
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiAccountLock,
  mdiLockOpen,
  mdiPlus,
  mdiEyeOutline,
  mdiRefresh,
  mdiLock,
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import ProxiMarginFormDialog from '@core/components/proxiMargin/ProxiMarginFormDialog'
import { formatDate } from '@core/date/dateFunctions'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import { onMounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import router from '@/router'
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";

export default {
  name: 'ProxiMargin',
  components: {
    ButtonsGroup,
    CustomToolbar, ProxiMarginFormDialog, ConfirmDialog, LoaderDialog,
  },

  setup() {
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }

    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const proxiMargin = ref(null)
    const confirmMessage = ref('Voulez-vous désactiver cette marge?')
    const proxiMarginForm = {
      id: null,
      min_distance: null,
      max_distance: null,
      margin: null,
    }

    const formattingDate = date => formatDate(date)

    const deleteItemConfirm = () => {
      // this.userList.splice(this.editedIndex, 1)
      closeDelete()
    }

    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.id } })
    }

    const closeDelete = () => {
      dialogDelete.value = false
    }

    const activateProxiMargin = () => {
      isDialogVisible.value = true
      store.dispatch('proxiMargin/activateProxiMargin', proxiMargin.value).then(response => {
        isDialogVisible.value = false
        notify(true, true, response.message)
      }).catch(error => {
        isDialogVisible.value = false
        notify(false, true, error.response.data.message)
        console.log(error)
      })
      closeDelete()
    }

    const deleteItem = item => {
      confirmMessage.value = item.is_locked ? 'Voulez-vous activer la marge?' : 'Voulez-vous désactiver la marge?'
      proxiMargin.value = item
      dialogDelete.value = true
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const getItems = (page = 1) => {
      isDialogVisible.value = true
      store.dispatch('proxiMargin/getProxiMarginList', { page, field: store.state.proxiMargin.field }).then(() => {
        currentPage.value = store.getters['proxiMargin/current_page']
        lastPage.value = store.getters['proxiMargin/last_page']
        isDialogVisible.value = false
      }).catch(error => {
      })
    }

    // eslint-disable-next-line camelcase
    const getItemsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        isDialogVisible.value = true
        // eslint-disable-next-line camelcase
        store.state.proxiMargin.field.paginate = per_page

        // this.itemsPerPage = parseInt(per_page, 10)
        store.dispatch('proxiMargin/getProxiMarginList', {
          page: store.getters['proxiMargin/current_page'],
          field: store.state.proxiMargin.field,
        }).then(() => {
          currentPage.value = store.getters['proxiMargin/current_page']
          lastPage.value = store.getters['proxiMargin/last_page']
          isDialogVisible.value = false
        })
      }
    }

    const getItemsByFields = () => {
      isDialogVisible.value = true
      store.dispatch('proxiMargin/getProxiMarginList', {
        page: 1,
        field: store.state.proxiMargin.field,
      }).then(() => {
        currentPage.value = store.getters['proxiMargin/current_page']
        lastPage.value = store.getters['proxiMargin/last_page']
        isDialogVisible.value = false
      })
    }

    const openForm = (item = null) => {
      if (item !== null) proxiMargin.value = item
      else {
        initForm()
        proxiMargin.value = proxiMarginForm
      }
      dialog.value = true
    }

    const initForm = () => {
      proxiMarginForm.id = null
      proxiMarginForm.margin = null
      proxiMarginForm.max_distance = null
      proxiMarginForm.min_distance = null
    }

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    onMounted(() => {
      getItems(store.getters['proxiMargin/current_page'] ? store.getters['proxiMargin/current_page'] : null)
      proxiMargin.value = proxiMarginForm
    })

    return {
      initForm,
      loading,
      openForm,
      getItemsByFields,
      getItems,
      getItemsPerPage,
      deleteItem,
      closeDialog,
      activateProxiMargin,
      closeDelete,
      openItem,
      formattingDate,
      deleteItemConfirm,
      currentPage,
      lastPage,
      dialog,
      dialogDelete,
      isDialogVisible,
      proxiMarginForm,
      proxiMargin,
      confirmMessage,
      headers: [
        { text: 'DISTANCE MIMINALE (KM)', value: 'min_distance' },
        { text: 'DISTANCE MAXINALE (KM)', value: 'max_distance' },
        { text: 'MARGE (%)', value: 'margin' },
        { text: 'ACTIF?', value: 'is_active' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.proxiMargin.field.paginate, 10),
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
        mdiRefresh,
        mdiLock,
      },
    }
  },
}
</script>

<style scoped>

</style>
