<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ proxiMargin.id !== null ? "Modifier marge" : "Nouvelle marge" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="proxiMargin.min_distance"
                    type="number"
                    label="Distance minimale (KM)"
                    :rules="[rules.min_distance]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="proxiMargin.max_distance"
                    label="Distance maximale (KM)"
                    type="number"
                    :rules="[rules.max_distance]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="proxiMargin.margin"
                    label="Marge (%)"
                    type="number"
                    :rules="[rules.margin_required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ proxiMargin.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getCurrentInstance, ref, watch } from '@vue/composition-api'
import { mdiCalendar, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'ProxiMarginFormDialog',
  emits: ['clickout'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    proxiMargin: null,
  },

  setup(props) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const proxiMarginForm = {
      id: null,
      min_distance: null,
      max_distance: null,
      margin: null,
    }
    const rules = {
      margin_required: true,
      max_distance: true,
      min_distance: true,
    }
    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      message.value = ""
      rules.margin_required = true
      rules.max_distance = true
      rules.min_distance = true
      instance.emit('clickout')
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      if (props.proxiMargin.id !== null) {
        store.dispatch('proxiMargin/updateProxiMargin', props.proxiMargin).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.margin_required = error.response.data.errors.margin !== undefined ? error.response.data.errors.margin[0] : true
            rules.max_distance = error.response.data.errors.max_distance !== undefined ? error.response.data.errors.max_distance[0] : true
            rules.min_distance = error.response.data.errors.min_distance !== undefined ? error.response.data.errors.min_distance[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('proxiMargin/addProxiMargin', props.proxiMargin).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          close()
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.margin_required = error.response.data.errors.margin !== undefined ? error.response.data.errors.margin[0] : true
            rules.max_distance = error.response.data.errors.max_distance !== undefined ? error.response.data.errors.max_distance[0] : true
            rules.min_distance = error.response.data.errors.min_distance !== undefined ? error.response.data.errors.min_distance[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    return {
      isPasswordVisible,
      isPasswordConfirmationVisible,
      proxiMarginForm,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
